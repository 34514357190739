<script setup lang="ts">
useHead({
    bodyAttrs: {
        class: 'bg-gray-50 text-gray-900',
    },
});
</script>

<template>
    <div>
        <div class="flex min-h-screen">
            <div class="min-h-screen grow">
                <header v-if="$slots.header">
                    <slot name="header" />
                    Notification
                </header>
                <main class="h-full max-w-full">
                    <NuxtLoadingIndicator />
                    <slot />
                </main>
            </div>
        </div>
        <JamBaseNotificationWrapper />
    </div>
</template>
